<template>
  <v-container>
    <div class="top">
      <v-row class="text-center">
        <v-col cols="12">
          <v-img
            src="../assets/hane_icon.jpg"
            class="my-3"
            contain
            height="150"
          />
        </v-col>

        <v-col class="mb-4">
          <div class="text-h4 font-weight-bold mb-3">羽原 丈博<br>Takehiro Habara</div>

          <p class="subheading font-weight-regular">
            京都大学大学院情報学研究科<br />
            通信情報システムコース&nbsp;博士後期課程1年<br /><br />
            Spikng Neural Networkあたりを研究<br /><br />
            <v-icon color="blue">mdi-twitter</v-icon>
            <a
              href="https://twitter.com/barla_wings"
              class="info--text"
              target="_blank"
              >@barla_wings</a
            ><br />
            <v-icon color="blue">mdi-twitter</v-icon>
            <a
              href="https://twitter.com/wings_neha"
              class="info--text"
              target="_blank"
              >@wings_neha</a
            >
          </p>
        </v-col>

        <v-col class="mb-5" cols="12">
          <h2 class="headline font-weight-bold mb-3">Contents</h2>

          <v-row justify="center">
            <a
              v-for="(inter, i) in interlinks"
              :key="i"
              :href="inter.href"
              class="subheading mx-3 info--text"
            >
              {{ inter.text }}
            </a>
          </v-row>
        </v-col>

        <v-col class="mb-5" cols="12">
          <h2 class="headline font-weight-bold mb-3">Links</h2>

          <v-row justify="center">
            <a
              v-for="(ext, i) in extralinks"
              :key="i"
              :href="ext.href"
              class="subheading mx-3 info--text"
              target="_blank"
            >
              {{ ext.text }}
            </a>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'HomeTop',

  data: () => ({
    extralinks: [
      {
        text: 'Github',
        href: 'https://github.com/hanebarla'
      },
      {
        text: 'Qiita',
        href: 'https://qiita.com/barla_flap'
      },
      {
        text: 'KaiRA',
        href: 'https://kyoto-kaira.github.io/'
      }
    ],
    interlinks: [
      {
        text: 'Blog',
        href: 'blog/'
      },
      {
        text: 'Portfolio',
        href: 'portfolio/'
      }
    ]
  })
}
</script>
